import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "../../services/connections/api";

export const generalService = createApi({
    reducerPath: "generalService",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["generalService"],
    endpoints: builder => ({
        getVendorTypes: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/vendor-types/?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getLicenseTypes: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/license-types?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getCompanyTypes: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/company-types?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getServices: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/services?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getcountries: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/countries?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getstates: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/states?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getcities: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/cities?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getzipCodes: builder.query<any, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/zip-codes?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        getVehicleTypes: builder.query<any, any>({
            query: () => ({
                url: "/vehicle-types",
                method: "GET",
            }),
        }),
        getVehicle: builder.mutation<any, { data: string}>({
            query: ({ data }) => ({
                url: `/vehicles/${data}`,
                method: "GET",
                data: data,
            }),
        }),
        getUsersSelectedVehicle: builder.query<any, any>({
            query: () => ({
                url: "/vendor-vehicles",
                method: "GET",
            }),
        }),
        getEquipments:  builder.query<any, any>({
            query: () => ({
                url: "/equipments",
                method: "GET",
            }),
        }),
        getUsersSelectedEquipments:  builder.query<any, any>({
            query: () => ({
                url: "/vendor-equipments",
                method: "GET",
            }),
        }),
        updateVendorVehicles: builder.mutation<any, any>({
            query: ({ data }) => ({
                url: "/vendor-vehicles/add",
                method: "POST",
                body: { "data": data },
            }),
        }),
        updateVendorEquipments: builder.mutation<any, any>({
            query: ({ data }) => ({
                url: "vendor-equipments/add",
                method: "POST",
                body: { "data": data },
            }),
        }),
    }),
});

export const { useGetVendorTypesQuery, useGetLicenseTypesQuery, useGetCompanyTypesQuery, useGetServicesQuery, useGetcountriesQuery, useGetstatesQuery, useGetcitiesQuery, useGetzipCodesQuery, useGetVehicleTypesQuery, useGetVehicleMutation, useGetEquipmentsQuery, useGetUsersSelectedEquipmentsQuery, useGetUsersSelectedVehicleQuery, useUpdateVendorEquipmentsMutation, useUpdateVendorVehiclesMutation } = generalService;
