import { combineReducers } from "@reduxjs/toolkit";

import appReducer from "./app/reducer";
import { bannersRequestApi } from "./banners/api";
import { vendorApi } from "./vendor";
import { generalService } from "./vendor/GeneralService";
import { vendorsAPIs } from "./vendor/VendorsAPIs";
import { authApi } from "../services/auth";
import { maintenancePlansApi } from "../services/maintenance-plans";
import { maintenanceRequestsApi } from "../services/maintenance-requests";
import { productsApi } from "../services/products";
import { propertiesApi } from "../services/properties";
import { servicesApi } from "../services/services";
import { subscriptionsApi } from "../services/subscriptions";
import { transactionsApi } from "../services/transactions";

const rootReducer = combineReducers({
    app: appReducer,
    [authApi.reducerPath]: authApi.reducer,
    [propertiesApi.reducerPath]: propertiesApi.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    [generalService.reducerPath]: generalService.reducer,
    [vendorsAPIs.reducerPath]: vendorsAPIs.reducer,
    [bannersRequestApi.reducerPath]: bannersRequestApi.reducer,
    [maintenanceRequestsApi.reducerPath]: maintenanceRequestsApi.reducer,
    [maintenancePlansApi.reducerPath]: maintenancePlansApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
