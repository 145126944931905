import { lazy, ComponentType, LazyExoticComponent } from "react";

import { getUserRole } from "../shared/getRole";

export interface IROUTE_ITEM {
    id: number | string;
    path: string;
    component: LazyExoticComponent<ComponentType<any>>;
    isPrivate: boolean;
    isRole: string;
}

export const ROUTES: IROUTE_ITEM[] = [
    {
        id: 1,
        path: "/",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => {
            if (getUserRole() === "resident") {
                return import("./dashboard");
            } else if (getUserRole() === "vendor") {
                return import("./vendor/profile");
            } else {
                return import("./Home");
            }
        }),
        isPrivate: Boolean(getUserRole()),
        isRole: "",
    },
    {
        id: 2,
        path: "/profile",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./profile")),
        isPrivate: true,
        isRole: "resident",
    },
    {
        id: 3,
        path: "/home-services",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./home-services")),
        isPrivate: true,
        isRole: "resident",
    },
    {
        id: 4,
        path: "/symn-luxe",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./luxe")),
        isPrivate: true,
        isRole: "resident",
    },
    {
        id: 5,
        path: "/sign-in",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./sign-in")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 6,
        path: "/sign-up",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./sign-up")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 6,
        path: "/sign-up/resident",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./resident")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 6,
        path: "/sign-up/vendor",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./vendor")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 7,
        path: "/maintenance-request",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./maintenance-request")),
        isPrivate: true,
        isRole: "resident",
    },
    {
        id: 8,
        path: "/home-services/:id",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./single-service")),
        isPrivate: true,
        isRole: "resident",
    },
    //Vendor routes
    {
        id: 9,
        path: "/orders",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./vendor/orders")),
        isPrivate: true,
        isRole: "vendor",
    },
    {
        id: 10,
        path: "/profile-setup",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./vendor/profile")),
        isPrivate: true,
        isRole: "vendor",
    },
    {
        id: 11,
        path: "/truck-vehicle-type",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./vendor/truck-vehicle-type")),
        isPrivate: true,
        isRole: "vendor",
    },
    {
        id: 12,
        path: "/notifications",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./vendor/notifications")),
        isPrivate: true,
        isRole: "vendor",
    },
    {
        id: 13,
        path: "/home",
        component: lazy(() => import("./Home")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 14,
        path: "/property-management",
        component: lazy(() => import("./property-management")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 15,
        path: "/association-management",
        component: lazy(() => import("./association-management")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 16,
        path: "/contact-us",
        component: lazy(() => import("./contact-us")),
        isPrivate: false,
        isRole: "",
    },
    {
        id: 17,
        path: "/dashboard/*",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./dashboard")),
        isPrivate: true,
        isRole: "resident",
    },
    {
        id: 18,
        path: "/subscription",

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: lazy(() => import("./subscription")),
        isPrivate: true,
        isRole: "resident",
    },
];
