import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import {
    ICurrentUserResponse,
    ISignInRequest,
    ISignInResponse,
    ISignUpRequest,
    ISignUpResponse, IUpdateProfileRequest, IUpdateProfileResponse,
    IfetchProfileResponse, IUpdateMaintenancePlanRequest, IUpdateMaintenancePlanResponse,
} from "../shared/interfaces/auth";

const ENDPOINT_PREFIX = "auth";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Auth"],
    endpoints: builder => ({
        getCurrentUser: builder.query<ICurrentUserResponse, any>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "GET",
            }),
        }),
        signUp: builder.mutation<ISignUpResponse, ISignUpRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/sign-up`,
                method: "POST",
                body: data,
            }),
        }),
        signIn: builder.mutation<ISignInResponse, ISignInRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/sign-in`,
                method: "POST",
                body: data,
            }),
        }),
        updateProfile: builder.mutation<IUpdateProfileResponse, IUpdateProfileRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "PATCH",
                body: data,
            }),
        }),
        updateMaintenancePlan: builder.mutation<IUpdateMaintenancePlanResponse, IUpdateMaintenancePlanRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/maintenance-plan`,
                method: "PATCH",
                body: data.data,
            }),
        }),
        fetchProfileData: builder.mutation<IfetchProfileResponse, IUpdateProfileRequest>({
            query: data => ({
                url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data}`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${data}`,
                    Accept: "application/json",
                },
            }),
        }),
    }),
});

export const {
    useSignUpMutation,
    useSignInMutation,
    useUpdateMaintenancePlanMutation,
    useGetCurrentUserQuery,
    useUpdateProfileMutation,
    useFetchProfileDataMutation,
} = authApi;
