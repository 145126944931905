import Cookies from "js-cookie";

export const ACCESS_TOKEN_NAME = "access_token";

export const COOKIES_CONSENT_NAME = "cookies_consent";

export const setTokenToCookies = (token: string): void => {
    Cookies.set(ACCESS_TOKEN_NAME, token);
};

export const getTokenFromCookies = (): string | null => {
    return Cookies.get(ACCESS_TOKEN_NAME) || null;
};

export const removeTokenFromCookies = (): void => {
    Cookies.remove(ACCESS_TOKEN_NAME);
};

export const setConsentToCookies = (value: string): void => {
    Cookies.set(COOKIES_CONSENT_NAME, value);
};

export const getConsentFromCookies = (): string | null => {
    return Cookies.get(COOKIES_CONSENT_NAME) || "";
};
