import qs from "qs";

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

export const withGlobalFilter = (global: string) => {
    return {
        global: global,
    };
};

export const getServicesQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    kind = undefined,
}: any) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...kind !== undefined && {
            kind,
        },
    }, { encode: false });
};

export const getBannersQueryString = ({
    pageIndex = 1,
    pageSize = 20,
}: any) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
    }, { encode: false });
};
