import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import { ICreateCheckoutSessionBody } from "../shared/interfaces/transactions";

const ENDPOINT_PREFIX = "transactions";

export const transactionsApi = createApi({
    reducerPath: "transactionsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Transactions", "Transaction"],
    endpoints: builder => ({
        createCheckoutSession: builder.mutation<any, ICreateCheckoutSessionBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/create-checkout-session`,
                method: "POST",
                body: data.data,
            }),
        }),
    }),
});

export const {
    useCreateCheckoutSessionMutation,
} = transactionsApi;
