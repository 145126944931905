import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "../../services/connections/api";
import { IBannersResponse, IGetBannersQuery } from "../../shared/interfaces/banners";
import { getBannersQueryString } from "../../shared/utils";

const ENDPOINT_PREFIX = "banners";

export const bannersRequestApi = createApi({
    reducerPath: "bannersRequestApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Banners"],
    endpoints: builder => ({
        getBanners: builder.query<IBannersResponse, IGetBannersQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getBannersQueryString(query)}`,
                method: "GET",
            }),
        }),
    }),
});

export const { useGetBannersQuery } = bannersRequestApi;
