import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import { IServicesResponse, IServiceResponse } from "../shared/interfaces/services";
import { getServicesQueryString } from "../shared/utils";

const ENDPOINT_PREFIX = "services";

export const servicesApi = createApi({
    reducerPath: "servicesRequestApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Services"],
    endpoints: builder => ({
        getServices: builder.query<IServicesResponse, any>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getServicesQueryString(query)}`,
                method: "GET",
            }),
        }),
        getServicesById: builder.query<IServiceResponse, any>({
            query: id => ({ url: `/${ENDPOINT_PREFIX}/${id}` }),
        }),
        createService: builder.mutation<any, any>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useCreateServiceMutation,
    useGetServicesByIdQuery,
    useGetServicesQuery,
} = servicesApi;
