import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../index";
import initialState from "../initial-store";

interface AppState {
    beganSubscription: boolean;
}

const initialAppState: AppState = initialState.app;

const appSlice = createSlice({
    name: "app",
    initialState: { ...initialAppState },
    reducers: {
        setBeganSubscription(state, { payload }) {
            state.beganSubscription = payload;
        },
    },
});

export const {
    setBeganSubscription,
} = appSlice.actions;

export default appSlice.reducer;

const selectSelf = (state: RootState) => state;

export const getBeganSubscription = createSelector(
    selectSelf,
    (state: RootState) => state.app.beganSubscription
);
