import "./styles/scss/index.scss";

import { Suspense } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Route, Routes } from "react-router-dom";

import { ScrollProvider } from "./components/ScrollContext";
import { ROUTES } from "./pages";
import PrivateRoute from "./pages/PrivateRoute";

function App() {
    const clientId = process.env.REACT_APP_GOOGLE_API_ID || "";

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <ScrollProvider>

                <Routes>
                    {ROUTES.map(el => {
                        const RouteComponent = el.isPrivate ? (
                            <PrivateRoute component={el.component} role={el.isRole} />
                        ) : (
                            <el.component />
                        );
                        return (
                            <Route
                                key={el.id}
                                path={el.path}
                                element={<Suspense fallback={null}>{RouteComponent}</Suspense>}
                            />
                        );
                    })}
                </Routes>
            </ScrollProvider>

        </GoogleOAuthProvider>
    );
}

export default App;
