import Cookies from "js-cookie";

export const ROLE_NAME = "role";

export const COOKIES_CONSENT_NAME = "cookies_consent";

export const setUserRole = (role: string): void => {
    Cookies.set(ROLE_NAME, role);
};

export const getUserRole = (): string | null => {
    return Cookies.get(ROLE_NAME) || null;
};
export const removeUserRoleFromCookies = (): void => {
    Cookies.remove(ROLE_NAME);
};
