import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "../../services/connections/api";
import { IChangeOrderStatusResponse, IOrderListResponse } from "../../shared/interfaces/vendor";

export const vendorApi = createApi({
    reducerPath: "vendorApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["vendor"],
    endpoints: builder => ({
        orderList: builder.query<IOrderListResponse, any>({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/vendor-orders?${searchParams}`,
                    method: "GET",
                };
            },
            providesTags: ["vendor"],
        }),
        changeOrderStatus: builder.mutation<IChangeOrderStatusResponse, any>({
            query: ({ data, id }) => ({
                url: `/vendor-orders/update-status/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["vendor"],
        }),
    }),
});

export const { useOrderListQuery, useChangeOrderStatusMutation } = vendorApi;
