import React from "react";
import ReactDOM from "react-dom/client";

import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { store } from "./store";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ConfigProvider theme={{
                    token: {
                        colorPrimary: "#C32126",
                        colorPrimaryBorderHover: "#C32126",
                        colorPrimaryBgHover: "#C32126",
                    },
                }}>
                    <App />
                </ConfigProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
