import React, { createContext, useContext, useRef } from "react";

const ScrollContext = createContext(null);

export const ScrollProvider = ({ children }: any) => {
    const faqSectionRef = useRef<HTMLDivElement>(null);

    const scrollToFAQ = () => {
        if (faqSectionRef.current) {
            faqSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <ScrollContext.Provider value={{ faqSectionRef, scrollToFAQ } as any}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = () => useContext<any>(ScrollContext);
