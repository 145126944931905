import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import {
    IGetMaintenanceResponse,
    IMaintenanceRequest,
    IMaintenanceResponse,
} from "../shared/interfaces/maintenance-request";

const ENDPOINT_PREFIX = "maintenance-requests";

export const maintenanceRequestsApi = createApi({
    reducerPath: "maintenanceRequestApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["MaintenanceRequest"],
    endpoints: builder => ({
        getMaintenanceRequests: builder.query<IGetMaintenanceResponse, any>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "GET",
            }),
        }),
        getMaintenanceRequestsById: builder.query<any, any>({
            query: id => ({ url: `/${ENDPOINT_PREFIX}/${id}` }),
        }),
        createMaintenanceRequest: builder.mutation<IMaintenanceResponse, IMaintenanceRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useGetMaintenanceRequestsQuery,
    useGetMaintenanceRequestsByIdQuery,
    useCreateMaintenanceRequestMutation,
} = maintenanceRequestsApi;
