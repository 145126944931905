import { configureStore } from "@reduxjs/toolkit";

import { bannersRequestApi } from "./banners/api";
import rootReducer from "./root-reducer";
import { vendorApi } from "./vendor";
import { generalService } from "./vendor/GeneralService";
import { vendorsAPIs } from "./vendor/VendorsAPIs";
import { authApi } from "../services/auth";
import { maintenancePlansApi } from "../services/maintenance-plans";
import { maintenanceRequestsApi } from "../services/maintenance-requests";
import { productsApi } from "../services/products";
import { propertiesApi } from "../services/properties";
import { servicesApi } from "../services/services";
import { subscriptionsApi } from "../services/subscriptions";
import { transactionsApi } from "../services/transactions";

const middleWares: any = [
    authApi.middleware,
    propertiesApi.middleware,
    maintenanceRequestsApi.middleware,
    maintenancePlansApi.middleware,
    vendorApi.middleware,
    generalService.middleware,
    vendorsAPIs.middleware,
    servicesApi.middleware,
    subscriptionsApi.middleware,
    bannersRequestApi.middleware,
    transactionsApi.middleware,
    productsApi.middleware,
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...middleWares),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
