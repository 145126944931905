import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import { ICreateSubscriptionRequest, ISubscriptionsResponse } from "../shared/interfaces/subscriptions";

const ENDPOINT_PREFIX = "subscriptions";

export const subscriptionsApi = createApi({
    reducerPath: "subscriptionsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Subscriptions"],
    endpoints: builder => ({
        getSubscriptions: builder.query<ISubscriptionsResponse, any>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}`,
                method: "GET",
            }),
        }),
        getSubscriptionsById: builder.query<any, string>({
            query: id => ({ url: `/${ENDPOINT_PREFIX}/${id}` }),
        }),
        createSubscriptions: builder.mutation<any, ICreateSubscriptionRequest[]>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/bulk`,
                method: "POST",
                body: data,
            }),
        }),
        updateSubscription: builder.mutation<any, {id: string, data: any}>({
            query: ({ id, data }) => ({
                url: `${ENDPOINT_PREFIX}/${id}`,
                method: "PUT",
                body: data,
            }),
        }),
        deleteSubscription: builder.mutation<any, string>({
            query: id => ({
                url: `${ENDPOINT_PREFIX}/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useCreateSubscriptionsMutation,
    useGetSubscriptionsByIdQuery,
    useGetSubscriptionsQuery,
    useDeleteSubscriptionMutation,
    useUpdateSubscriptionMutation,
} = subscriptionsApi;
