import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "../../services/connections/api";

const ENDPOINT_PREFIX = "vendors";

export const vendorsAPIs = createApi({
    reducerPath: "vendorsAPIs",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["vendorsAPIs"],
    endpoints: builder => ({
        getnotifications: builder.query({
            query: params => {
                const searchParams = new URLSearchParams(params).toString();
                return {
                    url: `/${ENDPOINT_PREFIX}/notifications?${searchParams}`,
                    method: "GET",
                };
            },
        }),
        seennotifications: builder.mutation<any, { data: any}>({
            query: ({ data }) => ({
                url: `/${ENDPOINT_PREFIX}/notifications/seen`,
                method: "PATCH",
                body: data,
            }),
        }),
        acceptOrder: builder.mutation<any, { data: any}>({
            query: ({ data }) => ({
                url: "/vendor-orders/accept",
                method: "POST",
                body: data,
            }),
        }),
        getVendorProfile: builder.query<any, any>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}/profile`,
                method: "GET",
            }),
        }),
        updateVendorProfile: builder.mutation<any, { data: any; id: string }>({
            query: ({ data, id }) => ({
                url: `/${ENDPOINT_PREFIX}/profile/${id}`,
                method: "PUT",
                body: data,
            }),
        }),
        fileUpload: builder.mutation<any, FormData>({
            query: data => ({
                url: "/files",
                method: "POST",
                body: data,
            }),
        }),
        multifileUpload: builder.mutation<any, FormData>({
            query: data => ({
                url: "/files/multi-files",
                method: "POST",
                body: data,
            }),
        }),
        contactUsForm: builder.mutation< any, any >({
            query: data => ({
                url: "/schedule-free-demo",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const { useGetnotificationsQuery, useSeennotificationsMutation, useAcceptOrderMutation, useGetVendorProfileQuery, useUpdateVendorProfileMutation, useFileUploadMutation, useMultifileUploadMutation, useContactUsFormMutation } = vendorsAPIs;
