import { FC, useEffect, useState } from "react";

import { Navigate } from "react-router-dom";

import { useGetCurrentUserQuery } from "../services/auth";
import { getTokenFromCookies } from "../shared/cookies-actions";

interface PrivateRouteProps {
    component: FC<any>;
    props?: any;
    role: string;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, role, ...props }) => {
    const token = getTokenFromCookies();
    const { data: userData, isLoading } = useGetCurrentUserQuery(undefined);
    const [userRole, setUserRole] = useState("");

    useEffect(() => {
        if (userData && userData.user) {
            setUserRole(userData.user.role);
        }
    }, [userData]);

    if (token && (isLoading || !userRole)) {
        return <div>Loading...</div>;
    }

    return token && (role === "" || role === userRole) ? (
        <>
            <Component {...props} />
        </>
    ) : (
        // <Navigate to="/sign-in" />
        <Navigate to="/home" />
    );
};

export default PrivateRoute;
