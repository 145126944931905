import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import { IProperty, IPropertyResponse, IPropertiesResponse } from "../shared/interfaces/properties";

const ENDPOINT_PREFIX = "properties";

export const propertiesApi = createApi({
    reducerPath: "propertiesApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Properties"],
    endpoints: builder => ({
        getProperties: builder.query<IPropertiesResponse, any>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "GET",
            }),
        }),
        getPropertyById: builder.query<IPropertyResponse, string>({
            query: id => ({ url: `/${ENDPOINT_PREFIX}/${id}` }),
        }),
        deletePropertyById: builder.query<IPropertyResponse, string>({
            query: id => ({
                url: `/${ENDPOINT_PREFIX}/${id}`,
                method: "DELETE",
            }),
        }),
        updatePropertyById: builder.query<IPropertyResponse, any>({
            query: ({ id, data }) => ({
                url: `/${ENDPOINT_PREFIX}/${id}`,
                method: "PUT",
                body: data,
            }),
        }),
        createProperty: builder.mutation<any, IProperty>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useGetPropertiesQuery,
    useGetPropertyByIdQuery,
    useCreatePropertyMutation,
    useLazyDeletePropertyByIdQuery,
    useLazyUpdatePropertyByIdQuery,
} = propertiesApi;
