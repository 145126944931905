import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import { IGetMaintenancePlansResponse } from "../shared/interfaces/maintenance-plans";

const ENDPOINT_PREFIX = "maintenance-plans";

export const maintenancePlansApi = createApi({
    reducerPath: "maintenancePlansApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["MaintenancePlan"],
    endpoints: builder => ({
        getMaintenancePlans: builder.query<IGetMaintenancePlansResponse, any>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetMaintenancePlansQuery,
} = maintenancePlansApi;
