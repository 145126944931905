import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "./connections/api";
import { IGetProductsResponse, IGetProductsRequest } from "../shared/interfaces/products";

const ENDPOINT_PREFIX = "products";

export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Product"],
    endpoints: builder => ({
        getProducts: builder.query<IGetProductsResponse, IGetProductsRequest>({
            query: query => ({
                url: `/${ENDPOINT_PREFIX}${query.kind ? `?kind=${query.kind}` : ""}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetProductsQuery,
} = productsApi;
